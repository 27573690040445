import type { BlockHighlightRealEstateFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { SingleHighlightEntry } from '../components/SingleHighlightEntry'
import { HighlightBlockAsCards } from './HighlightBlockAsCards'
import { HighlightReel } from './HighlightReel'

//Keys must match contentful variant
const VariantMapping: Record<
  string,
  FunctionComponent<React.PropsWithChildren<{ block: BlockHighlightRealEstateFragment }>>
> = {
  HighlightReel: HighlightReel,
  CardsInRows: HighlightBlockAsCards,
  SingleEntry: SingleHighlightEntry,
}

export const BlockHighlightRealEstate: FunctionComponent<React.PropsWithChildren<{
  block: BlockHighlightRealEstateFragment
}>> = ({ block }) => {
  const variant = block.variant
  if (!variant) return null
  const Component = VariantMapping[variant]
  return <Component block={block} />
}
