import type { IconButtonProps} from '@material-ui/core';
import { IconButton, withStyles } from '@material-ui/core'
import type { FunctionComponent } from 'react'
import 'twin.macro'

const StyledIconButton = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    border: 2,
    borderStyle: 'solid',
  },
}))(IconButton)

export const SimpleIconButton: FunctionComponent<React.PropsWithChildren<IconButtonProps>> = ({
  children,
  ...props
}) => {
  return (
    <StyledIconButton tw="focus:outline-none" {...props}>
      {children}
    </StyledIconButton>
  )
}
