import {
  AnimateIconTranslationRight,
  GridWithGaps,
  HeadingH1,
  HeadingH4,
  useAnimateIconTranslationRight,
} from '@dreamstack/feature-components'
import { ArrowRight } from '@dreamstack/icons'
import type {
  BlockBigTitleNavigation,
  Maybe,
  StaticPage,
} from '@dreamstack/investors-graphql'
import { generateIdForText } from '@dreamstack/util'
import { motion } from 'framer-motion'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import tw, { styled } from 'twin.macro'
import type { additionAttributes } from '../types/ankerAttribute'
import { scrollToTarget } from '../utils/scrollToTarget'

const StyledTextLink = tw(
  motion.p
)`text-accentroAqua-full text-px-16 leading-px-24 
 flex items-center justify-end font-semibold md:(text-px-20 leading-px-30)`

const StyledButtonWrapper = styled.div<{ displayAsColumn?: boolean }>(
  ({ displayAsColumn }) => [
    tw`mt-px-20 flex w-full col-span-full md:(col-span-5 mt-0)`,
    displayAsColumn
      ? tw`md:(col-start-1 col-span-full mt-px-24) lg:(mt-px-48)`
      : tw`md:(items-end justify-end)`,
  ]
)
const StyledTitleWrapper = styled(HeadingH1)<{ displayAsColumn?: boolean }>(
  ({ displayAsColumn }) => [
    displayAsColumn ? tw`` : tw`md:(mt-px-8 col-span-7) lg:(mt-px-8)`,
    tw`col-span-full`,
  ]
)
const StyledSubTitleWrapper = styled(HeadingH4)<{ displayAsColumn?: boolean }>(
  ({ displayAsColumn }) => [
    displayAsColumn ? tw`` : tw`lg:(mt-px-8)`,
    tw`font-normal col-span-full`,
  ]
)
const StyledGridWrapper = styled(GridWithGaps)<{ displayAsColumn?: boolean }>(
  ({ displayAsColumn }) => [displayAsColumn && tw`grid-cols-6`]
)

const StyledButtonLink = styled.a<{ displayAsColumn?: boolean }>(
  ({ displayAsColumn }) => [!displayAsColumn && tw`md:(text-right)`]
)

export const BigTitleNavigation: FunctionComponent<
  React.PropsWithChildren<{
    block:
      | (Pick<
          BlockBigTitleNavigation,
          'title' | 'subTitle' | 'buttonText' | 'additionalInfoText' | 'url'
        > & {
          staticPageReference?: Maybe<Pick<StaticPage, 'slug'>>
        })
      | undefined
    displayAsColumn?: boolean
  }>
> = ({ block, displayAsColumn }) => {
  const showButton = !!block?.url || !!block?.staticPageReference
  const { onHoverStart, controls } = useAnimateIconTranslationRight()
  if (!block) return null
  const id = generateIdForText(block.title || '')

  const additionAttributes: additionAttributes = {}
  if (block?.url?.startsWith('#')) {
    additionAttributes.onClick = (
      event?: React.MouseEvent<Element, MouseEvent> | undefined
    ) => scrollToTarget(block.url || '', event)
  }

  return (
    <div>
      {block.subTitle && (
        <StyledGridWrapper displayAsColumn={displayAsColumn}>
          <StyledSubTitleWrapper displayAsColumn={displayAsColumn}>
            {block.subTitle}
          </StyledSubTitleWrapper>
        </StyledGridWrapper>
      )}
      <StyledGridWrapper displayAsColumn={displayAsColumn}>
        <StyledTitleWrapper id={id} displayAsColumn={displayAsColumn}>
          {block.title}
        </StyledTitleWrapper>
        <StyledButtonWrapper displayAsColumn={displayAsColumn}>
          {showButton && (
            <Link
              href={`${block?.url || block?.staticPageReference?.slug}`}
              passHref
              legacyBehavior
              {...additionAttributes}
            >
              <StyledButtonLink displayAsColumn={displayAsColumn}>
                <StyledTextLink onHoverStart={onHoverStart}>
                  <span>{block.buttonText}</span>
                  <AnimateIconTranslationRight animate={controls}>
                    <ArrowRight tw="w-px-32 h-px-32 ml-px-20" />
                  </AnimateIconTranslationRight>
                </StyledTextLink>
              </StyledButtonLink>
            </Link>
          )}
        </StyledButtonWrapper>
        {block.additionalInfoText && (
          <StyledSubTitleWrapper
            tw="row-start-2 pt-px-24"
            displayAsColumn={displayAsColumn}
          >
            <span tw="whitespace-pre-wrap">{block.additionalInfoText}</span>
          </StyledSubTitleWrapper>
        )}
      </StyledGridWrapper>
    </div>
  )
}
