import type { AnimationControls} from 'framer-motion';
import { motion, useAnimation } from 'framer-motion'
import type { FunctionComponent } from 'react'

export const AnimateIconTranslationRight: FunctionComponent<React.PropsWithChildren<{
  animate: AnimationControls
}>> = ({ animate, children }) => {
  return (
    <motion.span
      animate={animate}
      variants={{
        translateRight: { transition: { duration: 0.3 }, translateX: 10 },
        tranlateBack: { transition: { duration: 0.3 }, translateX: 0 },
      }}
    >
      {children}
    </motion.span>
  )
}

export const useAnimateIconTranslationRight = () => {
  const controls = useAnimation()
  return {
    controls,
    onHoverStart: async () => {
      await controls.start({ transition: { duration: 0.3 }, translateX: 10 })
      await controls.start({ transition: { duration: 0.3 }, translateX: 0 })
    },
  }
}

export const useAnimateIconTranslationLeft = () => {
  const controls = useAnimation()
  return {
    controls,
    onHoverStart: async () => {
      await controls.start({ transition: { duration: 0.3 }, translateX: -10 })
      await controls.start({ transition: { duration: 0.3 }, translateX: 0 })
    },
  }
}

export const AnimateIconTranslationLeft: FunctionComponent<React.PropsWithChildren<{
  animate: AnimationControls
}>> = ({ animate, children }) => {
  return (
    <motion.span
      animate={animate}
      variants={{
        translateRight: { transition: { duration: 0.3 }, translateX: -10 },
        tranlateBack: { transition: { duration: 0.3 }, translateX: 0 },
      }}
    >
      {children}
    </motion.span>
  )
}
