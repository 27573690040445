import { useRelativePositionOnPage } from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'
import type { BlockHighlightRealEstateFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import React, { useEffect, useRef } from 'react'
import { HighlightRealEstate } from './HighlightRealEstate'

export const padZero = (num: number, pad: number) =>
  num.toString().padStart(pad, '0')

export const HighlightRealEstateItem: FunctionComponent<
  React.PropsWithChildren<{
    index: number
    block: BlockHighlightRealEstateFragment
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  }>
> = ({ index, block, setActiveIndex }) => {
  const t = useTranslation()
  // const isFirst = index === 0

  const realEstates = block?.highlightedRealEstatesCollection?.items
  const activeRealEstate = realEstates?.[index]

  const ref = useRef<HTMLDivElement | null>(null)

  const { relativePosition } = useRelativePositionOnPage(ref)

  useEffect(() => {
    if (relativePosition > 0.0 && relativePosition < 0.2) {
      setActiveIndex(index)
    }
  }, [relativePosition, index, setActiveIndex])

  if (!activeRealEstate) return null

  return <HighlightRealEstate realEstate={activeRealEstate as any} ref={ref} />
}
