import { GridWithGaps } from '@dreamstack/feature-components'
import type { BlockHighlightRealEstateFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import 'twin.macro'
import { BigTitleNavigation } from '../blocks/BigTitleNavigation'
import type { HighlightRealEstateProps } from '../blocks/HighlightRealEstate'
import { HighlightRealEstate } from '../blocks/HighlightRealEstate'

// Random Number in const here to stay the same between renders
const randomNumber = Math.random()
/**
 * Martijn zu SEO-Implikationen von Zufall:
 * Also er sollte kein problem sein, es gibt aber einige "abers":
 * - Wenn es nur ein teil von der Seite ist die Dynamisch geladen wird ist es kein Problem, Google braucht "genügend" statische content um zu verstehen was auf der Seite steht. Sonnst kann mann ein Cloaking Penalty ausrichten - was wir nicht wollen.
 * - Verwende keine H1 oder H2 tags im Dynamische Element
 * - Dynamische Projekt verlinkungen können sogar helfen um Projekt Seiten Schneller zu indexieren, hier ist es aber wichtig das der Google Bot die züfallige URL lesen kann. (also ideal is hier ein serverside rendering) - ist ein Nice to have
 */

export const SingleHighlightEntry: FunctionComponent<
  React.PropsWithChildren<{
    block: BlockHighlightRealEstateFragment
  }>
> = ({ block }) => {
  const [index, setIndex] = useState(0)
  // Select Random Item
  const items: HighlightRealEstateProps[] =
    block.highlightedRealEstatesCollection?.items || []

  // Prevent hydration error on client
  useEffect(() => {
    setIndex(Math.floor(randomNumber * items.length))
  }, [setIndex, items.length])

  // No real estate is selected in contentful, so we do not display the component.
  if (!items.length) return null

  const realEstate = items[index]

  return (
    <>
      <GridWithGaps tw="col-span-full gap-y-px-24">
        <div tw="col-span-full md:(col-span-7)">
          <HighlightRealEstate realEstate={realEstate} />
        </div>
        <div tw="col-span-full row-start-1 md:(row-start-auto col-span-5 flex items-center)">
          <BigTitleNavigation
            displayAsColumn
            block={block?.bigTitleNavigation ?? undefined}
          />
        </div>
      </GridWithGaps>
    </>
  )
}
