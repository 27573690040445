import { useOnScreen } from '@dreamstack/util'
import { useEffect, useState } from 'react'
import { isBrowser } from '../utils/domUtils'

export const useRelativePositionOnPage = (
  target: React.MutableRefObject<HTMLElement | null>
) => {
  const [relativePosition, setRelativePosition] = useState<number>(0)
  const targetElement = target?.current
  const onScreen = useOnScreen(target)
  useEffect(() => {
    if (!isBrowser() || !onScreen || !targetElement) setRelativePosition(0)
    else {
      const handleScroll = () => {
        const absolutePositionInWindow =
          targetElement?.getBoundingClientRect().top ?? 1
        // Some relative value between 0 and 1, depending on where the element is on current windowY.
        const relativePositionTemp =
          absolutePositionInWindow / window.innerHeight
        setRelativePosition(relativePositionTemp)
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [targetElement, onScreen])

  return { relativePosition }
}
