import {
  GridWithGaps,
  HeadingH3,
  HeadingH4,
  SimpleIconButton,
  Text_16_24,
} from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'
import { ArrowRight } from '@dreamstack/icons'
import type { BlockHighlightRealEstateFragment } from '@dreamstack/investors-graphql'
import { SimpleDefinitionList } from '@dreamstack/simple-components'
import { motion } from 'framer-motion'
import Image from 'next/legacy/image'
import Link from 'next/link'
import React from 'react'
import tw from 'twin.macro'

const StyledHighlightCard = tw.div`bg-accentroGray-full text-accentroWhite-full p-px-16 -mt-px-8 md:(px-px-24 py-px-32 -mt-px-48 mr-px-32 ml-px-128)
ml:(ml-px-16) lg:(ml-px-128 -mt-px-80 px-px-48)`

export type HighlightRealEstateProps = NonNullable<
  BlockHighlightRealEstateFragment['highlightedRealEstatesCollection']
>['items'][number]

export const HighlightRealEstate = React.forwardRef<
  HTMLDivElement,
  { realEstate: HighlightRealEstateProps }
>(({ realEstate }, ref) => {
  const t = useTranslation()
  if (!realEstate) return null
  return (
    <GridWithGaps tw="" ref={ref}>
      <motion.div tw="col-span-12">
        <div tw="aspect-w-3 aspect-h-2">
          <Image
            tw="object-cover"
            src={realEstate?.image?.url ?? ''}
            alt={realEstate?.image?.description || 'Property image'}
            layout="fill"
          />
        </div>
      </motion.div>
      <GridWithGaps tw="col-span-full flex flex-col-reverse md:(grid)">
        <motion.div tw="w-full relative col-start-1 col-span-12 row-start-auto">
          <StyledHighlightCard>
            <HeadingH3>{realEstate.title}</HeadingH3>
            <Text_16_24 tw="mt-px-4">{realEstate.subTitle}</Text_16_24>
            <div tw="grid grid-cols-2 gap-px-16 mt-px-32 md:(grid-cols-4) lg:(flex flex-row justify-between)">
              {realEstate.rooms && (
                <SimpleDefinitionList
                  big
                  dt={
                    <HeadingH4 tw="whitespace-nowrap">
                      {realEstate.rooms}
                    </HeadingH4>
                  }
                  dd={
                    <p tw="text-px-16 leading-px-24 mt-px-4">
                      {t('accentro:room.rooms')}
                    </p>
                  }
                />
              )}
              {realEstate.squareMeter && (
                <SimpleDefinitionList
                  big
                  dt={
                    <HeadingH4 tw="whitespace-nowrap">
                      {realEstate.squareMeter + ' '}
                      m²
                      {/* <Text_20_30 tw="inline">m²</Text_20_30> */}
                    </HeadingH4>
                  }
                  dd={
                    <p tw="text-px-16 leading-px-24 mt-px-4">
                      {t('accentro:livingSpace')}
                    </p>
                  }
                />
              )}
              {(realEstate.price || realEstate.pricePerSquareMeter) && (
                <SimpleDefinitionList
                  big
                  dt={
                    <HeadingH4 tw="whitespace-nowrap">
                      {realEstate.price + ' '}
                      EUR
                      {/* <Text_20_30 tw="inline">EUR</Text_20_30> */}
                    </HeadingH4>
                  }
                  dd={
                    <p tw="text-px-16 leading-px-24 mt-px-4">
                      {realEstate.pricePerSquareMeter + ' EUR /'}
                      <span>&nbsp;m²</span>
                    </p>
                  }
                />
              )}

              <div tw="flex justify-end col-start-2 md:col-start-4">
                <Link
                  href={realEstate.slug ?? '#'}
                  passHref
                  tw="text-accentroWhite-full"
                >
                  <SimpleIconButton color="inherit">
                    <ArrowRight tw="text-accentroWhite-full h-px-32 w-px-32" />
                  </SimpleIconButton>
                </Link>
              </div>
            </div>
          </StyledHighlightCard>
        </motion.div>
      </GridWithGaps>
    </GridWithGaps>
  )
})

HighlightRealEstate.displayName = 'HighlightRealEstate'
