import type { HighlightRealEstate } from '@dreamstack/accentro-contentful/src/blocks/HighlightRealEstate'
import { useLocale } from '@dreamstack/i18n'
import filter from 'lodash/filter'
import map from 'lodash/map'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import type { ProjectPreview } from '../index'
import type { ComingSoonPreview } from '../projects/ComingSoonPreview'

const ProjectWrapper = styled.div<{
  isLeftRow?: boolean
  isRightRow?: boolean
}>(({ isLeftRow, isRightRow }) => [
  isLeftRow && tw`md:(pr-px-24) lg:(pr-px-32)`,
  isRightRow && tw`md:(pl-px-24) lg:(pl-px-32)`,
  tw`w-full`,
])
const Row = styled.div(() => [tw`w-1/2 space-y-px-64 md:space-y-px-128`])

export const ProjectPreviewLayout: FunctionComponent<{
  children: React.ReactElement<
    | typeof ComingSoonPreview
    | typeof ProjectPreview
    | typeof HighlightRealEstate
  >[]
}> = ({ children }) => {
  const locale = useLocale()

  const leftRow = useMemo(() => {
    return filter(children, (_, i) => i % 2 === 0)
  }, [children])

  const rightRow = useMemo(() => {
    return filter(children, (_, i) => i % 2 === 1)
  }, [children])

  return (
    <>
      <div tw="space-y-px-64 md:hidden">
        {map(children, (project) => (
          <ProjectWrapper key={`${project.key}${locale}`}>
            {project}
          </ProjectWrapper>
        ))}
      </div>
      <div tw="hidden md:flex">
        <Row>
          {map(leftRow, (project) => (
            <ProjectWrapper isLeftRow key={`${project.key}${locale}`}>
              {project}
            </ProjectWrapper>
          ))}
        </Row>
        <Row tw="pt-px-128">
          {map(rightRow, (project) => (
            <ProjectWrapper isRightRow key={`${project.key}${locale}`}>
              {project}
            </ProjectWrapper>
          ))}
        </Row>
      </div>
    </>
  )
}
