import {
  Css_HeadingH3,
  Css_Text_16_24_To_13_18_5,
  Css_Text_20_30_To_16_24,
} from '@dreamstack/feature-components'
import React from 'react'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

// const DefinitionList = tw.dl`${(props) => {
//   return props.big ? 'text-lg' : 'text-base'
// }} font-montserrat`

const DefinitionList = styled.ol<{ big?: boolean }>(({ big }) => [
  big ? tw`text-lg` : tw`text-base`,
  tw`font-montserrat`,
])

// const Description = tw.dd`${(props) => {
//   return props.big ? 'text-lg font-semibold' : 'font-semibold'
// }}`

const Description = styled.dd<{ big?: boolean }>(({ big }) => [
  big ? Css_Text_20_30_To_16_24 : Css_Text_16_24_To_13_18_5,
])

const DefinitionTerm = styled.dt<{ big?: boolean }>(({ big }) => [
  tw`font-semibold`,
  big ? Css_HeadingH3 : Css_Text_20_30_To_16_24,
])

export const SimpleDefinitionList: FunctionComponent<React.PropsWithChildren<{
  dt: string | React.ReactNode
  dd: string | React.ReactNode
  className?: string
  big?: boolean
}>> = ({ dt, dd, className, big }) => {
  return (
    <DefinitionList big={big} className={className}>
      <DefinitionTerm big={big}>{dt}</DefinitionTerm>
      <Description big={big}>{dd}</Description>
    </DefinitionList>
  )
}
