import { HeadingH1 } from '@dreamstack/feature-components'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import { padZero } from './HighlightRealEstateItem'

const StyledHeading = tw(
  HeadingH1
)`font-variant-numeric[tabular-nums] md:py-px-12 lg:py-px-24`

export const HighlightRealEstatePageNumber: FunctionComponent<React.PropsWithChildren<{
  index: number
  length: number
}>> = ({ index, length }) => {
  const page = index + 1
  const formattedPageNumber = padZero(page, 2)
  const formattedLength = padZero(length, 2)
  return (
    <div tw="text-center relative lg:static">
      <StyledHeading>{formattedPageNumber}</StyledHeading>
      <StyledHeading tw="text-accentroGray-400 absolute -bottom-full">
        {formattedLength}
      </StyledHeading>
    </div>
  )
}
