import { ProjectPreviewLayout } from '@dreamstack/feature-components'
import type { BlockHighlightRealEstateFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { HighlightRealEstate } from './HighlightRealEstate'

export const HighlightBlockAsCards: FunctionComponent<
  React.PropsWithChildren<{
    block: BlockHighlightRealEstateFragment
  }>
> = ({ block }) => {
  const realEstates = block?.highlightedRealEstatesCollection?.items || []
  return (
    <>
      <ProjectPreviewLayout>
        {realEstates.map((p, i) => (
          <HighlightRealEstate realEstate={p} key={i} />
        ))}
      </ProjectPreviewLayout>
    </>
  )
}
