import {
  FullGridWidthDiv,
  GridWithGaps,
  MainGridStyled,
  useIsMlOrGreater,
  useIsSmOrSmaller,
} from '@dreamstack/feature-components'
import type { BlockHighlightRealEstateFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent} from 'react';
import { useState } from 'react'
import 'twin.macro'
import { BigTitleNavigation } from './BigTitleNavigation'
import { HighlightRealEstateItem } from './HighlightRealEstateItem'
import { HighlightRealEstatePageNumber } from './HighlightRealEstatePageNumber'

export const HighlightReel: FunctionComponent<React.PropsWithChildren<{
  block: BlockHighlightRealEstateFragment
}>> = ({ block }) => {
  const realEstates = block?.highlightedRealEstatesCollection?.items
  const isSm = useIsSmOrSmaller()
  const isMl = useIsMlOrGreater()
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <GridWithGaps tw="col-span-full">
      <FullGridWidthDiv tw="col-span-full bg-accentroAquaVeryLight-full top-0 height[250px] md:(height[250px] z-20 sticky) ml:(height[550px] z-auto) lg:(height[650px])">
        <MainGridStyled tw="col-span-full h-full">
          <div tw="flex items-center col-span-full md:(col-span-11) ml:(max-width[490px]) lg:(max-w-none col-span-3 z-auto)">
            <div tw="w-full">
              <BigTitleNavigation
                displayAsColumn={isMl || isSm}
                block={block.bigTitleNavigation ?? undefined}
              />
            </div>
          </div>
        </MainGridStyled>
      </FullGridWidthDiv>
      <FullGridWidthDiv tw="col-span-full bg-accentroAquaVeryLight-full hidden md:(block height[366px] top[150px] z-index[9] sticky) ml:(top[550px] height[108px]) lg:(top[650px])">
        <MainGridStyled tw="col-span-full h-full">
          <div tw="hidden md:(flex items-end col-start-12 col-span-1 justify-center z-10 max-height[min-content]) ml:(col-span-1 col-start-12 pr-0)">
            <HighlightRealEstatePageNumber
              length={realEstates?.length ?? 0}
              index={activeIndex}
            />
          </div>
        </MainGridStyled>
      </FullGridWidthDiv>
      <div tw="z-10 col-span-full space-y-px-48 mt-px-48 md:(col-span-11 margin-top[-366px] mb-px-32 space-y-px-80) ml:(col-start-6 col-span-6 margin-top[-490px]) lg:(margin-top[-560px])">
        {realEstates?.map((_, i) => {
          return (
            <HighlightRealEstateItem
              key={i}
              index={i}
              block={block}
              setActiveIndex={setActiveIndex}
            />
          )
        })}
      </div>
    </GridWithGaps>
  )
}
